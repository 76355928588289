.btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    z-index: 10;
    width: 100%;
    gap: 20px;
    div {
        margin: 1em;
    }
}

.white-button {
    border-radius: 10px;
    border: 1px solid var(--main-color);
    text-align: center;
    padding: 8px 15px;
    color: var(--main-color);
    box-sizing: border-box;
    background-color: white;
    font-size: 15px;
}

.white-button:hover,
.white-button-active {
    transition: all 0.2s linear;
    background-color: var(--main-color);
    color: white;
    cursor: pointer;
}

.gray-border-button {
    min-width: 90px;
    border-radius: 30px;
    // border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-size: 10px;
    text-align: center;
    transition: all 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.disabled-btn {
    border-radius: 10px;
    background-color: #bdbdbd;
    color: white;
    padding: 8px 15px;
    text-align: center;
    transition: all 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
}

.border-purple {
    border: 1px solid var(--light-purple);
}

.gray-border-button:hover,
.gray-border-button-active {
    background-color: var(--primary-color);
    color: var(--white);
}

.filters-button,
.group-actions-button,
.new-folder-button {
    color: var(--white);
    display: flex;
    justify-content: space-around;
    padding: 8px 15px;
    font-size: 15px;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
}

.filters-button, .new-folder-button {
    background-color: var(--main-color);
    color: var(--white);
    width: 90px;
}
.new-folder-button {
    background-color: var(--main-color);
    color: var(--white);
    width: 130px;
}

// .filters-button:hover {
//     background-color: var(--light-purple);
// }

.group-actions-button {
    background-color: white;
    color: var(--main-color);
    border: 1px solid var(--main-color);
    width: 140px;
}

.group-actions-button:hover,
.group-actions-button-active {
    background-color: var(--main-color);
    color: white;
}

.light-purple-circle-button {
    border-radius: 50%;
    --b: 4px; /* the thickness */
    width: 20px; /* the size */
    height: 20px;
    aspect-ratio: 1/1;
    border: 10px solid var(--light-purple); /* the outer space */
    background: conic-gradient(from 90deg at var(--b) var(--b), var(--light-purple) 90deg, #fff 0)
        calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) / calc(50% + var(--b)) calc(50% + var(--b));
    display: inline-block;
    box-shadow: 0px 0px 5px rgba(46, 46, 46, 0.3);
    cursor: pointer;
}

.minus {
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 50%;
    border: 1px solid var(--gray-5);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: var(--my-gray);
    cursor: pointer;
    .minus-inside {
        width: 20px;
        height: 4px;
        background-color: var(--gray-5);
    }
}

.light-purple-button,
.error-button {
    border-radius: 10px;
    color: white;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    width: auto;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    img {
        margin-left: 15px;
        width: 20px;
    }
    // border: 3px solid var(--light-purple);
}

.light-purple-button {
    border: 1px solid var(--light-purple);
    background-color: var(--light-purple);
}

.light-purple-button:hover {
    background-color: #c3c1f7;
}

.error-button {
    border: 1px solid var(--pink);
    background-color: var(--pink);
}

.error-button:hover {
    background-color: #fa559f;
}

.gray-button {
    border-radius: 10px;
    background-color: #d8dbed;
    color: black;
    text-align: center;
    padding: 10px 15px;
    cursor: pointer;
    width: 200px;
    box-sizing: border-box;
}

.gray-button:hover {
    background-color: #b3b3b3;
}

.main-color-button {
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
    color: white;
    border-radius: 10px;
    color: white;
    padding: 10px 15px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 15px;
    display: flex;
    justify-content: center;
    > img {
        margin-left: 15px;
        width: 20px;
    }
}

.main-color-button:hover {
    background-color: #494577;
}

.rounded-blue-button {
    cursor: pointer;
    color: #ffffff;
    border-radius: 50px;
    padding: 16px 33.4px 14px 40px;
    background-color: #5e71db;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    padding: 0 1rem;
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 32px;
    letter-spacing: 0.1px;
    text-align: left;
    gap: 16px;
    align-items: center;
    border: 1px solid transparent;

    &.sm {
    font-size: 14px;
    padding: 0 12px;
    line-height: 24px;
    }

    &.upload-button {
        border-radius: 20px;
        border: solid 1px var(--blue-10);
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--blue-10);
        font-size: 16px;
        font-weight: 600;
        padding: 11px 2rem 11px 2rem;
        margin-top: 10px;
        
        &:hover {
            color: #ffffff;
            background-color: var(--blue-10);
        }
    }

    &.transparent {
        color: var(--blue-10);
        background-color: transparent;
        border: solid 1px var(--blue-10);
    }

    &:hover {
        opacity: 0.8;
    }

    &:disabled,
    &[disabled] {
        opacity: 0.3;
    }
}

.cssCircle {
    border-radius: 999px;
    width: 26px;
    height: 26px;
    padding: 0px;
    border: 1px solid #003580;
    color: #003580;
    text-align: center;
    transition: color 0.2s linear;
    font: 26px Arial, sans-serif;
}

.cssCircle:hover {
    color: #ffffff;
    cursor: pointer;
}

.minusSign {
    line-height: 0.9em;
    margin-bottom: 1px;
}

.plusSign {
    color: #ffffff;
    background-color: #000000;
    border-radius: 50px;
    box-shadow: 0 0 5px 0 rgb(46 46 46 / 30%);
    background-image: linear-gradient(to top, #8497ff, #122171);
    line-height: 26px;
    border: 1px solid transparent;
}

.general-rounded-btn {
    display: inline-block;
    border-radius: 25px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    color: #ffffff;
    &.blue-grad {
        background-image: linear-gradient(180deg,
            hsl(231deg 100% 76%) 0%,
            hsl(231deg 78% 70%) 11%,
            hsl(232deg 63% 65%) 22%,
            hsl(233deg 53% 59%) 33%,
            hsl(233deg 45% 54%) 44%,
            hsl(234deg 42% 48%) 56%,
            hsl(234deg 45% 43%) 67%,
            hsl(234deg 51% 37%) 78%,
            hsl(233deg 59% 32%) 89%,
            hsl(231deg 73% 26%) 100%);

    &:hover {
        color: #ffffff;
        background-image: linear-gradient(90deg,
                hsl(231deg 100% 76%) 0%,
                hsl(231deg 78% 70%) 11%,
                hsl(232deg 63% 65%) 22%,
                hsl(233deg 53% 59%) 33%,
                hsl(233deg 45% 54%) 44%,
                hsl(234deg 42% 48%) 56%,
                hsl(234deg 45% 43%) 67%,
                hsl(234deg 51% 37%) 78%,
                hsl(233deg 59% 32%) 89%,
                hsl(231deg 73% 26%) 100%);
    }
    }
    &.purple-grad {
        background-image: linear-gradient(180deg,
        hsl(248deg 90% 73%) 0%,
        hsl(247deg 88% 72%) 11%,
        hsl(246deg 86% 71%) 22%,
        hsl(245deg 85% 70%) 33%,
        hsl(245deg 83% 69%) 44%,
        hsl(243deg 82% 68%) 56%,
        hsl(242deg 80% 67%) 67%,
        hsl(241deg 79% 67%) 78%,
        hsl(240deg 78% 66%) 89%,
        hsl(239deg 77% 64%) 100%);

    &:hover {
        color: #ffffff;
        background-image: linear-gradient(90deg,
                hsl(231deg 100% 76%) 0%,
                hsl(231deg 78% 70%) 11%,
                hsl(232deg 63% 65%) 22%,
                hsl(233deg 53% 59%) 33%,
                hsl(233deg 45% 54%) 44%,
                hsl(234deg 42% 48%) 56%,
                hsl(234deg 45% 43%) 67%,
                hsl(234deg 51% 37%) 78%,
                hsl(233deg 59% 32%) 89%,
                hsl(231deg 73% 26%) 100%);
    }
    }
}

.p-button.btn-gradient-blue {
    background-image: linear-gradient(90deg,
            hsl(231deg 67% 44%) 0%,
            hsl(232deg 58% 48%) 25%,
            hsl(233deg 55% 52%) 45%,
            hsl(233deg 58% 56%) 60%,
            hsl(233deg 61% 59%) 71%,
            hsl(233deg 64% 63%) 79%,
            hsl(232deg 69% 66%) 85%,
            hsl(232deg 74% 70%) 91%,
            hsl(231deg 80% 73%) 95%,
            hsl(231deg 88% 77%) 100%);
    font-size: #{fluid(14px, 16px, 320px, 1600px)};

    img {
        width: #{fluid(26px, 28px, 320px, 1600px)};
    }

    span {}

    .p-button-icon {}
}