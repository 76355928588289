.folder,
.folder-white {
    width: 100px;
    height: 67px;
    margin-top: 50px;
    position: relative;
    background-color: white;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.59);
    cursor: pointer;
}
