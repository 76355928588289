// @import "../node_modules/@syncfusion/ej2-material-theme/styles/material.css";
@import "./assets/styles/hover.css";
// @import "../node_modules/primeng/resources/themes/saga-blue/theme.css";
@import "../node_modules/primeicons/primeicons.css";
@import "./assets/styles/my-buttons.scss";
@import "./assets/styles/toastr.css";
@import "./assets/styles/folder.scss";
@import './sass/_mixins.scss';

$font-family-light: 'Proxima Nova Light';

//*Proxima Nova
@font-face {
    font-family: "Proxima Nova Light";
    src: url(./assets/fonts/proxima_ssv/ProximaNova-AltLight.otf) format("opentype");
}
@font-face {
    font-family: "Proxima Nova Thin";
    src: url(./assets/fonts/proxima_ssv/ProximaNova-AltThin.otf) format("opentype");
}
@font-face {
    font-family: "Proxima Nova Regular";
    src: url(./assets/fonts/proxima_ssv/ProximaNova-Regular.otf) format("opentype");
}
@font-face {
    font-family: "Proxima Nova SemiBold";
    src: url(./assets/fonts/proxima_ssv/ProximaNova-AltBold.otf) format("opentype");
}
@font-face {
    font-family: "Proxima Nova Bold";
    src: url(./assets/fonts/proxima_ssv/ProximaNova-AltBold.otf) format("opentype");
}

//* Lato 
@font-face {
    font-family: "Lato Light";
    src: url(./assets/fonts/Lato/Lato-Light.ttf) format("truetype");
}
@font-face {
    font-family: "Lato Regular";
    src: url(./assets/fonts/Lato/Lato-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "Lato Semibold";
    src: url(./assets/fonts/Lato/Lato-Bold.ttf) format("truetype");
}
@font-face {
    font-family: "Lato Bold";
    src: url(./assets/fonts/Lato/Lato-Black.ttf) format("truetype");
}

//* Raleway
@font-face {
    font-family: "Raleway Bold";
    src: url(./assets/fonts/Raleway/Raleway-Bold.ttf) format("truetype");
}
/* General */
$scale: 14px;
/* main font size */
$borderRadius: 12px;
/* border radius of layout element e.g. card, sidebar */
$transitionDuration: .2s;
/* transition duration of layout elements e.g. sidebar, overlay menus */

//* text sizes
$text-3xs: 0.5rem;
$text-2xs: 0.65rem;
$text-xs: 0.75rem;
$text-sm: 0.85rem;
$text-base: 1rem;
$text-lg: 1.1rem;
$text-xl: 1.2rem;
$text-2xl: 1.3rem;
$text-3xl: 1.4rem;
$text-4xl: 1.6rem;

$txt-btn-sm: 0.925rem;


//* gradients
$trial-default-gradient: linear-gradient(to left, #927ffa 0%, #5a5fe9 100%)!important;
$arranged-default-gradient: linear-gradient(to left, #6a61d4 0%, #5b43ba 100%) !important;
$completed-gradient: linear-gradient(#8497FF 0%, #48d 60.34%, #122171 100%)!important;
$trial-arranged-default-gradient: linear-gradient(#a795ff 0%, #6e25ff 100%)!important;
$trial-hover-gradient: linear-gradient(to left, #927ffa 0%, #5a5fe9 50%)!important;
*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    font-family: 'Proxima Nova Regular', -apple-system, BlinkMacSystemFont, "Segoe UI", "Microsoft YaHei", "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei",
    /* Japanese */
    "Meiryo", "Hiragino Kaku Gothic Pro", "Noto Sans JP", "Yu Gothic", "MS Gothic", 
    /* Korean */
    "Malgun Gothic", "Apple SD Gothic Neo", "Nanum Gothic", "Noto Sans KR", 
    /* Fallbacks */
    sans-serif;

    &.p-component {
        font-family: 'Proxima Nova Regular', sans-serif;
    }
    // letter-spacing: 0.044rem;
    line-height: 20px;
    color: #2D2A4B;
    b {
        font-family: 'Proxima Nova Bold', sans-serif;
    }
    p {
        font-family: 'Proxima Nova Regular', sans-serif;
        font-size: $text-xs;
    }
    .font-semiBold {
        font-family: 'Proxima Nova Semibold', sans-serif!important;
    }
    .font-semibold {
        font-family: 'Proxima Nova Semibold', sans-serif!important;
    }
    .font-light {
        font-family: 'Proxima Nova Light', sans-serif!important;
    }
    .font-bold {
        font-family: 'Proxima Nova Bold', sans-serif!important;
    }
    .font-3xs {
        font-size: $text-3xs!important;
    }
    .font-2xs {
        font-size: $text-2xs!important;
    }
    .font-xs {
        font-size: $text-xs!important;
    }
    .font-sm {
        font-size: $text-sm!important;
    }
    .font-base {
        font-size: $text-base!important;
    }
    .font-lg{
        font-size: $text-lg!important;
    }
    .font-xl, h4{
        font-size: $text-xl!important;
    }
    .font-2xl{
        font-size: $text-2xl!important;
    }
    .font-3xl {
        font-size: $text-3xl!important;
    }
    .font-4xl {
        font-size: $text-4xl!important;
    }
    .font-btn-sm {
        font-size: $txt-btn-sm!important;
    }

    // font grows 1px for every 100px of viewport width
    //   font-size: calc(1rem + 1vw);
    // leading grows along with font,
    // with an additional 0.1em + 0.5px per 100px of the viewport
    //   line-height: calc(1.1em + 0.5vw);
    // @include fluid-typography(12,16,300,1600);
    // font-size: calc(0.5vw + 10px);
    font-size: #{fluid(13px, 16px, 320px, 1600px)};

    @media (max-width: 1366px) {

        // font-size: 75%!important;
        // zoom: 0.9;
        // font-size: 1.3vw;
    }

}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 14px;
}

*::-webkit-scrollbar-track {
    background: #d2d2ff;
    border-radius: 100px;
}

*::-webkit-scrollbar-thumb {

    background: linear-gradient(#4354b4 0%, #4354b47d 100%);
    opacity: 0.12;
    border-radius: 100px;
    border: 1px none #363636;
}

// @font-face {
//     font-family: "Raleway";
//     src: local("Raleway"), url(./assets/fonts/Raleway/Raleway-Regular.ttf) format("truetype");
// }
// @font-face {
//     font-family: "Raleway-bold";
//     src: local("Raleway"), url(./assets/fonts/Raleway/Raleway-Bold.ttf) format("truetype");
// }
// strong {
//     font-family: "Raleway-bold";
// }
// .bold {
//     font-family: "Raleway-bold";
// }
:root {
    --primary-color: #2e3d90;
    --secondary-color: #57b894;
    --black: #000000;
    --white: #ffffff;
    --gray: #efefef;
    --gray-2: #757575;

    --facebook-color: #4267b2;
    --google-color: #db4437;
    --twitter-color: #1da1f2;
    --insta-color: #e1306c;

    --pink: #f72585;
    --purple: #b5179e;
    --dark-purple: #7209b7;
    --light-purple: #CCD6FF;
    --light-purple-2: #657AEF;
    --main-color: #2d2a4b;
    --blue-2: #3f37c9;
    --blue-3: #4361ee;
    --blue-4: #4895ef;
    --blue-5: #4cc9f0;
    --blue-6: #031977;
    --blue-7: #96a7ff;
    --blue-8: #2e3d90;
    --blue-9: #1a266c;
    --blue-10: #3a4997;
    --blue-11: #5e71db;
    --orange: #f27769;
    --gray-0: #f8faff;
    --gray-1: #93949e;
    --gray-2: #cacbd9;
    --gray-3: #d7daec;
    --gray-4: #eaf0f5;
    --gray-5: #7d7d7d;
    --my-gray: rgb(220, 224, 229);
    --my-gray-2: #707070;
    --gray-border: #bdbdbd;
    --gray-border-2: #d8d9e8;
    --white: #ffffff;
    --app-content-width: 1300px;
    --page-width: 1034px;
    --menu-width: 260px;
    --lato-15px: 15px;
    --menu-to-page-padding: 16px;
    --content-margin: 20px 0px;
    --img-profile-width: 120px;
    --box-shadow-menu: 2px 5px 6px rgba(0, 0, 0, 0.16);
    --surface-ground: #faf9f8;
    --surface-section: #ffffff;
    --surface-card: #ffffff;
    --surface-overlay: #ffffff;
    --surface-border: #edebe9;
    --surface-hover: #f3f2f1;


}




@media only screen and (min-width: 1024px) and (max-width: 1366px) {
    :root {
        --app-content-width: 100%;
        --page-width: 910px;
        --menu-width: 70px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    :root {
        --app-content-width: 100%;
        --page-width: 98%;
        --menu-width: 70px;
    }
}
#mobile-menu {
    display: none;
}
@media only screen and (max-width: 768px) {
    :root {
        --app-content-width: 100%;
        --page-width: 100%;
        --menu-width: 0;
    }
    #header,
    #left-menu {
        display: none;
    }
    #mobile-menu {
        display: block;
    }
}
@media screen and (min-width: 768px){
    .width-30 {
        width: 30% !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (max-height: 850px) {
    :root {
        --menu-width: 100%;
    }
}

body,
html {
    padding: 0;
    margin: 0;
    background-color: #EFF4FF;
    z-index: 0;
    box-sizing: border-box;
    // overflow-x: hidden;
}

body {
    overflow-x: hidden;
}

hr {
    height: 1px;
    background-color: #ccc;
    border: none;
}

.w-100 {
    width: 100% !important;
    flex-basis: 100% !important;
}

.m-l-100 {
    margin-left: 100px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-l-5 {
    margin-left: 5px;
}

.display-none {
    display: none;
}

.display-flex {
    display: flex;
}

.display-block {
    display: block;
}

.pointer {
    cursor: pointer;
}

.f-s-15 {
    font-size: 15px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-15 {
    margin-top: 15px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-15 {
    margin-bottom: 15px;
}

a,
a:hover,
a:visited,
a:focus {
    text-decoration: none;
    color: white;
}

.link-color {
    color: var(--white);
    cursor: pointer;
    transition: color 0.2s linear;
}

.link-color:hover {
    color: var(--light-purple);
}

.link-main-color {
    color: var(--main-color);
    cursor: pointer;
    // transition: all 0.2s linear;
    // white-space: nowrap;
}
.link-main-color:hover {
    color: var(--light-purple-2);
}

.link-purple-color {
    color: var(--light-purple);
    cursor: pointer;
    // transition: all 0.2s linear;
    // white-space: nowrap;
}
.link-purple-color:hover {
    color: var(--main-colot);
}

.link-main-color-active {
    background: linear-gradient(#657aef 0%, #1a266c 100%);
    color: white;
    
    &:hover {

        color: white;
    }
}

.align-center {
    align-items: center;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-space-around {
    justify-content: space-around;
}

.justify-end {
    justify-content: flex-end;
}

.row {
    display: flex;
    flex: 0 1 auto;
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    @media only screen and (min-width: 640px) {
        flex-direction: row;
    }
}

.col--1of7 {
    @media only screen and (min-width: 1366px) {
        flex-basis: 12.5%;
        max-width: 12.5%;
    }
    @media only screen and (min-width: 768px) and (max-width: 1366px) {
        flex-basis: 25%;
        max-width: 25%;
        margin-top: 20px;
    }
    @media only screen and (max-width: 768px) {
        flex-basis: 50%;
        max-width: 50%;
        margin-top: 20px;
    }
}

.col--1of8 {
    @media only screen and (min-width: 1366px) {
        flex-basis: 12.5%;
        max-width: 12.5%;
    }
    @media only screen and (min-width: 768px) and (max-width: 1366px) {
        flex-basis: 25%;
        max-width: 25%;
        margin-top: 20px;
    }
    @media only screen and (max-width: 768px) {
        flex-basis: 50%;
        max-width: 50%;
        margin-top: 20px;
    }
}

.col--1of6 {
    @media only screen and (min-width: 1366px) {
        flex-basis: 16.6666667%;
        max-width: 16.6666667%;
    }
    @media only screen and (min-width: 768px) and (max-width: 1366px) {
        flex-basis: 33.3333%;
        max-width: 33.3333%;
        margin-top: 20px;
    }
    @media only screen and (max-width: 768px) {
        flex-basis: 50%;
        max-width: 50%;
        margin-top: 20px;
    }
}

.col--1of5 {
    transition: all 0.2s ease-in;
    @media only screen and (min-width: 1366px) {
        flex-basis: 20%;
        max-width: 20%;
    }
    @media only screen and (min-width: 768px) and (max-width: 1366px) {
        flex-basis: 25%;
        max-width: 25%;
    }
    @media only screen and (max-width: 768px) {
        flex-basis: 50%;
        max-width: 50%;
    }
}

.col--1of4 {
    transition: all 0.2s ease-in;
    @media only screen and (min-width: 1024px) {
        flex-basis: 25%;
        max-width: 25%;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        flex-basis: 33.3333%;
        max-width: 33.3333%;
    }
    @media only screen and (max-width: 768px) {
        flex-basis: 50%;
        max-width: 50%;
    }
}

.col--1of3 {
    @media only screen and (min-width: 640px) {
        flex-basis: 33.333%;
        max-width: 33.333%;
    }
}

.col--1of2 {
    @media only screen and (min-width: 640px) {
        flex-basis: 50%;
        max-width: 50%;
    }
}

.col--1of1 {
    flex-basis: 100%;
    max-width: 100%;
}

.abs-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
    color:darkred;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.v-align {
    top: 50%;
    transform: translateY(-50%);
}

.h-align {
    transform: translateX(-50%);
}

html,
body {
    height: 100%;
}
body {
    margin: 0;
    // font-family: Roboto, "Helvetica Neue", sans-serif;
}

.modal {
    max-width: 1024px;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    background: #ffffff;
    box-sizing: border-box;
    z-index: 100003;
    transition: all ease 0.5s;
    top: 54%;
    left: calc(50% + 140px);
    // left: calc(50%);
    transform: translate(-50%, -50%);
    overflow-y: scroll;
    padding: 20px;
    display: flex;
    flex-direction: column;
    max-height: 790px;
    box-sizing: border-box;
    overflow-x: hidden;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.center-abs {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.blur-bg {
    position: fixed;
    height: 110%;
    top: -80px;
    left: 0;
    width: 100%;
    background: rgba(4, 21, 20, 0.6);
    margin-top: 56px;
    margin-bottom: 1120px;
    z-index: 101;
    transition: all ease 0.5s;
}
.no-visibility {
    visibility: hidden;
    opacity: 0;
    transition: all ease 0.5s;
}
.yes-visibility {
    visibility: visible;
    opacity: 1;
    transition: all ease 0.5s;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.input-error {
    color: #9C27B0;
    // padding: 10px 10px 10px 0;
    // font-weight: bold;
}

.m-t-10 {
    margin-top: 10px;
}

.m-15 {
    margin: 15px;
}

.m-l-15 {
    margin-left: 15px;
}

.text-center {
    text-align: center;
}

.p-0-10 {
    padding: 0 10px 10px 10px;
}

.p-0-20 {
    padding: 0 20px 20px 20px;
}

.p-20 {
    padding: 20px !important;
}

.p-0 {
    padding: 0 !important;
}

.p-l-0 {
    padding-left: 0 !important;
}

.p-r-0 {
    padding-right: 0 !important;
}

.p-b-0 {
    padding-bottom: 0 !important;
}

.p-t-0 {
    padding-top: 0 !important;
}

.m-0 {
    padding: 0;
}

.step-heading-right {
    opacity: 0.44;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    text-align: left;
    color: #3a4997;
}

.step-heading-count-steps {
    position: absolute;
    // right: 46px;
    font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    text-align: left;
    color: var(--blue-11);
    span {
        font-weight: normal;
    }
}


.fuji {
    max-width: 818px;
    position: ABSOLUTE;
    bottom: 0;
    right: -228px;
    z-index: 1;
    mix-blend-mode: lighten;
}

.orb-canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
}

/* Not too many browser support this yet but it's good to add! */
@media (prefers-contrast: high) {
    .orb-canvas {
        display: none;
    }
}


/** GRADIENT BACKGROUND **/

.animatedwrapper {
    overflow: hidden;
    position: absolute;
    left: 0px;
    z-index: 0;
    width: 100%;
    height: 100vh;
    isolation: isolate;
    /* 
    Add fallback background here
    background: url();
    */

    background-image: linear-gradient(
  120deg,
  hsl(227deg 98% 83%) 0%,
  hsl(228deg 98% 81%) 4%,
  hsl(230deg 97% 80%) 8%,
  hsl(231deg 96% 78%) 12%,
  hsl(233deg 95% 77%) 18%,
  hsl(234deg 93% 75%) 26%,
  hsl(236deg 91% 74%) 37%,
  hsl(239deg 89% 72%) 53%,
  hsl(241deg 86% 71%) 75%,
  hsl(244deg 84% 68%) 100%
);
}

.content--canvas canvas {
    z-index: -1;
    position: absolute!important;
    display: none;
}

@media only screen and (max-width: 600px) {
    .dot {
        --size: 100px;
    }
}

@media only screen and (min-width: 992px) {
    .dot {
        --size: 800px;
        display: none;
        position: absolute;
        left: calc(var(--size) / 6 * -1);
        top: calc(var(--size) / 3 * -1);
        width: var(--size);
        height: var(--size);
        // mix-blend-mode: soft-light;
        border-radius: var(--size);
        filter: blur(150px);
        -webkit-filter: blur(150px); /* Safari 6.0 - 9.0 */
        -ms-filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius=150)"; /* IE 8-11, Edge */
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        animation: dot-animation ease-in-out 12s infinite;
        will-change: transform;


        @media only screen and (min-width: 1499px) {

            --size: 1200px;
        }
    }

    @supports (mix-blend-mode: soft-light) and (filter: blur(1px)) {
        .animatedwrapper {
            /* Remove the fallback background image when the features are supported */
            background: none;
        }

        .dot {
            /* Only show the animated dots for browsers that support the required features */
            display: block;
        }
    }

    .dot--one {
        background: #4361EE;
    }

    .dot--two {
        background: #927EFA;
        animation-delay: -5s;
    }

    .dot--three {
        background: #EAA84A;
        animation-delay: -12s;
    }

    @keyframes dot-animation {
        0% {
            transform: translate(0, 0);
        }
        33% {
            transform: translate(calc(var(--size) * 1.3), 0);
        }
        66% {
            transform: translate(calc(var(--size) * 1), calc(var(--size) / 1));
        }
        80% {
            transform: translate(calc(var(--size) / 1.2), calc(var(--size) / 1.2));
        }
        100% {
            transform: translate(0, 0);
        }
    }

}

.gradient-moving-body {
    height: 100vh;
    width: 100%;
    margin-top: 0 !important;
    background: linear-gradient(40deg,#F8FAFF,#3448b0,#5f7aea,#64cfdd,#0049B0,#2E3D90,
    #000B4A, #2B356C, #4A77BA, #aabaf3, #c4c4f4, #dee5f9,
            var(--blue-11));
    background-size: 400% 400%;
    -webkit-animation: GradiationAnimation 45s ease infinite;
    -moz-animation: GradiationAnimation 45s ease infinite;
    animation: GradiationAnimation 45s ease infinite;
    background-repeat: no-repeat;
    // height: 100vh;
    // display: flex;
    // flex-flow:row nowrap;
    // flex: 0 1 auto;
    // align-items: center;
    // justify-content:center;
    .bottom-footer {
        display: none;
    }
}

@keyframes GradiationAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.disabled-link {
    pointer-events: none;
    opacity: 0.5;
    cursor: default;
}

.disabled-div {
    pointer-events: none;
    opacity: 0.6;
}

.p-button.p-button-outlined {
    // box-shadow: none;
    // box-shadow: var(--primary-color);
    box-shadow: inset 0 0 0 1px;
}

.p-button.btn-social{
    color: #c5c5c5;
    border-radius: 8px;
    padding: 0.6rem #{fluid(4px, 7px, 320px, 1600px)};
    font-size: #{fluid(14px, 16px, 320px, 1600px)};
    img {
        width: #{fluid(26px, 28px, 320px, 1600px)};
    }
}

.p-button.p-button-icon-only.p-button-rounded.plus-btn-circle {
    width: 1.6rem;
    height: 1.6rem;
    box-shadow: 0 0 5px 0 rgb(46 46 46 / 30%);
    background-image: linear-gradient(to top, #8497ff, #122171);
    border-color: transparent;
    &.sm {
        width: 0.725rem;
        height: 0.725rem;
        padding: 0.6rem;
    }
    &.lg {
        width: 2.2rem;
        height: 2.2rem;
    }
}
.p-button.p-button-icon-only.p-button-rounded.p-button.minus-btn-circle {
    width: 1.6rem;
    height: 1.6rem;
    border: 1px solid #003580;
    background-color: transparent;
    &.sm {
        border: none;
        outline: 1px solid #003580;
        width: 0.725rem;
        height: 0.725rem;
        padding: 0.6rem;
    }
    .p-button-icon {
        color: #003580;
    }
}

.p-button.blue-teal {
    border-radius: 28px;
    background: #2FB9D3;
    transition: color 0.2s ease-in;
    &.p-button-lg {
        padding: 1rem 2rem;
    }
}

.p-button.blue-gradient-btn {
    border-radius: 28px;
    background: linear-gradient(#657aef 0%, #122171 100%);
    transition: color 0.2s ease-in;
    &.p-button-lg {
        padding: 1rem 2rem;
    }
}

.p-button.p-button-xs {
    font-size: 0.625rem;
    padding: 0.42475rem 0.625rem;  
}

.p-button.p-button-2xs {
    font-size: 0.425rem;
    padding: 0.22475rem 0.425rem;  
}

.price-blue {
    color: #3345A7;
}

.side-btn {
    font-weight: 400;
    border-radius: 12px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    &.light-blue-btn {
        background-color: var(--blue-11);
    }

    &.dark-blue-btn {
        background-color: var(--blue-8);
    }
}

.p-toast {
    @include breakpoint(mobile) {
        width: 100%;
    }
}
.toast-custom {
    .p-toast-message-content {
        width: 100%;
        justify-content: space-between;
        padding: 1rem!important;

        img {
            width: 50px;
        }

        p {
            font-size: 1rem;
            letter-spacing: 0.01em;
            line-height: 1rem;
            text-align: left;
            color: #fff;
            margin: 0;
        }
    }

    .p-toast-message {
        
        border-radius: 15px;

        .p-toast-icon-close {
            color: #ffffff !important;
        }

        &.p-toast-message-error {
            background: linear-gradient(#e44060 0%, #e37d6e 100%);
        }

        &.p-toast-message-info {
            background: linear-gradient(#927ffa 0%, #5a5fe9 100%);
        }

        &.p-toast-message-warn {
            background: linear-gradient(#ff9a50 0%, #ef7668 100%);
        }

        &.p-toast-message-success {
            background: linear-gradient(#8497ff 0%, #5163c0 41.22%, #122171 100%);
            color: #ffffff;


            .p-toast-message-content .p-toast-message-icon {
                // filter: invert(100%) sepia(7%) saturate(0%) hue-rotate(285deg) brightness(112%) contrast(100%);
                background-repeat: no-repeat;
                vertical-align: middle;
                padding-top: 10px;
                background-position: center center;

                &::before {
                    content: '';
                }
            }
        }
    }
}

.profile-info-section .input-fields .input-field .input-element-title {

    @include fluid-typography(14,16,300,1600);
    // font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align: left;
    color: #000;
    display: flex;
    align-items: center;
    gap: 12px;
}
.profile-info-section .input-fields .input-field {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 12px;
}
.profile-info-section .input-fields .input-field .input-element,
.profile-info-section .input-element {
    background-image: linear-gradient(#ffffff, #e3ebfd);
    font-family: inherit;
}
.input-fields {
    display: flex;
    flex-wrap: wrap;
    .input-field,
    .input-field-100 {
        margin-top: 20px;
        font-size: 18px;
        // padding: 20px 20px 0px 0;
        padding: 10px 10px 0 0px;
        box-sizing: border-box;
        .dropdown-blue.p-dropdown {
            margin-top: 10px;
            @include breakpoint(mobile) {
                margin-top: 4px;
            }
        }
        .input-element-title {
            color: var(--blue-9);
        }
        .input-element {
            margin-top: 10px;
            @include breakpoint(mobile) {
                margin-top: 4px;
            }
            color: var(--blue-10);
            width: 100%;
            box-sizing: border-box;
            letter-spacing: 0.01em;
            font-weight: 500;
        }
    }
    .input-field {
        width: 50%;
        flex-basis: 50%;
    }
    .input-field-100 {
        flex-basis: 100%;
        width: 100%;
    }
    @media screen and (max-width: 768px) {
        .input-field {
            width: 100%;
            flex-basis: 100%;
        }
    }
}

.status-color {
    color: #007dca;
}

.overlay-panel-width {
    width: 100%;
    top:35px!important;
    left: auto!important;
    margin-top: 0!important;
    background: linear-gradient(#F4F7FE 0%, #D3E0FC 100%);
}

.input-time-slot {
    max-width: 100%!important;
    border: 1px solid #949EEE;
    background: rgb(184, 205, 250);
    background: linear-gradient(0deg, rgba(184, 205, 250, 1) 0%, rgba(239, 244, 254, 1) 100%);
}

.input-element {
    border: 1px solid var(--my-gray);
    border-radius: 10px;
    padding: 11px;
    font-size: 1rem;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    text-align: left;
    color: var(--blue-9);

    &.phone-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.break {
    flex-basis: 100%;
    height: 0;
}

.p-inputgroup input.p-inputnumber-input {
    border-radius: 0;
    margin: 0;
}

.p-inputgroup:last-child input{
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.p-inputgroup:first-child input {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    &.btn-right {
        
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    }
}
.p-calendar-blue input[type="text"] {
    border: 1px solid var(--my-gray);
    border-radius: 10px;
    padding: 11px;
    // font-size: #{fluid(12px, 16px, 320px, 1600px)};
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    text-align: left;
    color: var(--blue-9);
    background-image: linear-gradient(#ffffff, #e3ebfd);
    height: 42px;
    // for calendar input
    &.p-inputtext,.p-inputtext {
        background: #fff;
        border-radius: 10px;
        padding-left: 45px;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: var(--blue-9);
            opacity: 0.5; /* Firefox */
          }
    }
}

.p-calendar-blue input[type="text"], .input-blue, .input-blue input {
    &.p-inputtext,.p-inputtext {

        @include fluid-typography(12,14,300,992);
        @include fluid-typography(12, 14,992,1500);
        
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        @include fluid-typography(12,14,300,992);
        @include fluid-typography(12, 14,992,1500);
        opacity: 0.5; /* Firefox */
      }
    }
}

.p-inputtext:enabled:focus.ng-invalid.ng-dirty {
    box-shadow: none;
}

.ng-dirty.ng-invalid > .p-dropdown {
    border-color: #3F37C9!important; 
}

.my-valid-input, .my-valid-input  .p-calendar-blue input[type=text] {
    border-color: #198754;
    padding-right: calc(1.5em + 0.75rem)!important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.my-invalid-input, .my-invalid-input  .p-calendar-blue input[type=text] {
    // .dropdown-blue.p-dropdown::before {
    //     content: '';
    //     position: absolute;
    //     right: 0;
    //     // @include invalid-state-icon;
    // }
    border-color: #3F37C9!important;
    // background: inherit;
    padding-right: 20px!important;
    @include invalid-state-icon;
}
.input-blue, .input-blue input {
    border: 1px solid var(--my-gray);
    border-radius: 10px;
    padding: 11px;
    // font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    text-align: left;
    color: var(--blue-9);
    height: 42px;
    // background-image: linear-gradient(#ffffff, #e3ebfd);
    &.gradient {
        background-image: linear-gradient(#ffffff, #e3ebfd);
    }
    &.gradient-blue {
        background: rgb(209,217,246);
        background: linear-gradient(180deg, rgba(209,217,246,1) 0%, rgba(255,255,255,1) 100%);
    }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--blue-9);
        opacity: 1; /* Firefox */
      }

    &.white-bg {
        background-color: #fff;
    }
    &.rounded {
        border-radius: 50px;
    }
    &.rounded-less {
        border-radius: 10px;
        &:focus {
            box-shadow: none;
        }
    }
    &.no-radius-left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

h1, h2, h3, h4, h5, h6 {
    margin: 1.5rem 0 1rem 0;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    @include fluid-typography(32, 40, 300, 1600);
}

h2 {
    @include fluid-typography(24, 32, 300, 1600);
}

h3 {
    @include fluid-typography(20, 28, 300, 1600);
}

h4 {
    @include fluid-typography(16, 24, 300, 1600);
}

h5 {
    @include fluid-typography(14, 20, 300, 1600);
}

h6 {
    font-size: 1rem;
}

mark {
    background: #FFF8E1;
    padding: .25rem .4rem;
    font-family: monospace;
}

blockquote {
    margin: 1rem 0;
    padding: 0 2rem;
    border-left: 4px solid #90A4AE;
}

hr {
    border-width: 1px 0 0 0;
    margin: 1rem 0;
}

p {
    margin: 0 0 1rem 0;
    line-height: 1.5;

    &:last-child {
        margin-bottom: 0;
    }
}

.fl-typo {
    &.s10-14 {
        @include fluid-typography(10, 14, 300, 1600);
    }
    &.s12-16 {
        @include fluid-typography(12, 16, 300, 1600);
    }
    &.s12-18 {
        @include fluid-typography(12, 18, 300, 1600);
    }
    &.s14-20 {
        @include fluid-typography(14, 20, 300, 1600);
    }
    &.s16-22 {
        @include fluid-typography(16, 22, 300, 1600);
    }
    &.s16-26 {
        @include fluid-typography(16, 26, 300, 1600);
    }
    &.s16-28 {
        @include fluid-typography(16, 28, 300, 1600);
    }
    &.s18-24 {
        @include fluid-typography(18, 24, 300, 1600);
    }
    &.s18-26 {
        @include fluid-typography(18, 26, 300, 1600);
    }
}



.avatar-circle {
    background-image: linear-gradient(180deg,
            hsl(209deg 80% 58%) 0%,
            hsl(212deg 69% 57%) 11%,
            hsl(216deg 58% 55%) 22%,
            hsl(220deg 48% 53%) 33%,
            hsl(224deg 40% 51%) 44%,
            hsl(229deg 35% 48%) 56%,
            hsl(235deg 31% 45%) 67%,
            hsl(242deg 30% 42%) 78%,
            hsl(250deg 31% 37%) 89%,
            hsl(257deg 33% 33%) 100%);
    // width: 110px;
    // height: 110px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.avatar-circle img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    border: 6px solid transparent;
    background-clip: padding-box;
    box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.7);
}


.blue-bg-gradient-1 {
    background-image: linear-gradient(to bottom, #162576, #333e95, #4d59b6, #6675d7, #7f92f9);
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
    background-image: linear-gradient(to right, #6c83ff, #152478);

    .menu-img {
        width: 24px;
        height: 24px;
        transform: scale(1.3);
    }

    .item-img {
        width: 30px;
        position: absolute;
        right: 10px;
        display: none;
    }

    .item:hover {
        background-color: rgba(164, 162, 230, 0.2) !important;
    }
}

.card {
    background: var(--surface-card);
    border: 1px solid var(--surface-border);
    // padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: var(--card-shadow);
    border-radius: $borderRadius;

    &:last-child {
        margin-bottom: 0;
    }
}

.p-datepicker {
    background: transparent;
    table{
        height: 100%;
        justify-content: center;
    }
}

.opaque-box-rtl {
    text-align: left;
    background-image: linear-gradient(-90deg, rgba(20, 20, 186, 0.35) 0%, rgba(201, 210, 255, 0) 100%);
    border-radius: 8px;
    padding: 1rem .5rem;
    &.blue {
        background: rgb(180,192,248);
        background: linear-gradient(270deg, rgba(180,192,248,1) 0%, rgba(255,255,255,1) 100%);
    }
}

.block-gradient {
    background: linear-gradient(#fff 0%, rgba(56, 115, 244, 0.2) 100%)!important;
    &.purple {
            background: linear-gradient(#fff 0%, rgba(210, 215, 255, 0.2) 100%)!important;
    }
}

.block-gradient-reverse {
    background: linear-gradient(rgba(56, 115, 244, 0.2)  0%, #fff 100%)!important;
    background-image: linear-gradient(
        180deg,
            hsl(221deg 100% 91%) 0%,
            hsl(221deg 100% 92%) 16%,
            hsl(221deg 100% 93%) 31%,
            hsl(221deg 100% 94%) 45%,
            hsl(221deg 100% 95%) 57%,
            hsl(221deg 100% 96%) 67%,
            hsl(220deg 100% 97%) 76%,
            hsl(220deg 100% 98%) 84%,
            hsl(220deg 100% 98%) 92%,
            hsl(220deg 100% 99%) 100%
            );
            &.purple {
                background: linear-gradient(rgba(212, 216, 255, 1) 0%, #F1F3FF 100%) !important;
            }
}

.block-header {
    padding: #{fluid(10px, 14px, 320px, 1600px)} 2rem;
    // background-color: var(--surface-section);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    // border:1px solid var(--surface-border);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .block-title {
        font-family: 'Proxima Nova Bold';
        display: inline-flex;
        align-items: center;
        position: relative;
        z-index: 2;
        font-size: #{fluid(16px, 24px, 320px, 1600px)};
        letter-spacing: 0.05rem;
        // @include fluid-typography(16,28,300,1500);
        .badge-free {
            border-radius: 4px;
            padding: .25rem .5rem;
            background-color: var(--orange-500);
            color: white;
            margin-left: 1rem;
            font-weight: 700;
            font-size: .875rem;
        }
    }

    .block-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
        margin-left: 1rem;

        a {
            display: flex;
            align-items: center;
            margin-right: .75rem;
            padding: .5rem 1rem;
            border-radius: 4px;
            border: 1px solid transparent;
            transition: background-color .2s;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            &:not(.block-action-disabled):hover {
                background-color: var(--surface-hover);
            }

            &.block-action-active {
                border-color: var(--primary-color);
                color: var(--primary-color);
            }

            &.block-action-copy {
                i {
                    color: var(--primary-color);
                    font-size: 1.25rem;
                }
            }

            &.block-action-disabled {
                opacity: .6;
                cursor: auto !important;
            }

            i {
                margin-right: .5rem;
            }
        }
    }
}

.p-datepicker:not(.p-datepicker-inline) {
    background: #ffffff !important;
}
.p-component {
    font-family: inherit;
    // font-size: inherit;
    font-weight: inherit;
}
.p-scrollpanel {
    &.custombar1 {
        .p-scrollpanel-wrapper {
            border-right: 9px solid linear-gradient(#002ccf 0%, #c9d2ff 100%) linear-gradient(#002ccf 0%, #c9d2ff 100%);

            //   background: linear-gradient(#002ccf 0%, #c9d2ff 100%) linear-gradient(#002ccf 0%, #c9d2ff 100%);
            //   opacity: 0.12;
        }

        .p-scrollpanel-bar {
            background: linear-gradient(#002ccf 0%, #c9d2ff 100%);
            opacity: 1;
            transition: background-color .2s;

            &:hover {
                background-color: #007ad9;
            }
        }
    }

    &.custombar2 {
        .p-scrollpanel-wrapper {
            border-right: 9px solid var(--layer-1);
            border-bottom: 9px solid var(--layer-1);
        }

        .p-scrollpanel-bar {
            background-color: var(--layer-2);
            border-radius: 0;
            opacity: 1;
            transition: background-color .2s;
            .p-scrollpanel-bar-x{
                display: none;
            }
        }
    }
}
.block-content {
    padding: 0;
    // border:1px solid var(--surface-border);
    border-top: 0 none;

    pre.app-code {
        background-color: var(--surface-card) !important;
        padding-bottom: 1rem !important;
        margin-bottom: 0 !important;

        code {
            padding: 1rem 2.5rem;
        }
    }
}

@media screen and (max-width: 575px) {
    .block-header {
        flex-direction: column;
        // align-items: start;

        .block-actions {
            margin-top: 1rem;
            margin-left: 0;
        }
    }
}

.p-dialog {
    border-radius: 8px;
}

.cd-confirm-dialog {
    .p-confirm-dialog .p-dialog-content {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        font-size: 1rem;
        letter-spacing: 0.01em;
        text-align: center;
        color: var(--blue-6);
    }
    .p-dialog {
        border-radius: 22px;
        overflow: hidden;
    }
    .p-dialog-header {
        align-items: center;
        justify-content: center;
    }
    .p-dialog .p-dialog-footer {
        display: flex;
        flex-direction: row-reverse;
    }
    .cd-dialog-btn-reject {
        padding: 8px 36px 9px 37px;
        border-radius: 18.5px;
        background: linear-gradient(#9baaff 0%, #152caf 100%);
    }

    .cd-dialog-btn-accept {
        padding: 8px 34px 9px 32px;
        border-radius: 18.5px;
        background-color: #ccd6ff;
        letter-spacing: 0.09px;
        text-align: left;
        color: #2e3d90;
    }

}
.file-path {
    font-weight: bold;
    font-size: 18px;
    padding: 8.2px 15.5px 7.8px 17.9px;
    border-radius: 54px;
    border: solid 1px #fff;
    background-color: var(--blue-10);
    line-height: 1;
    vertical-align: middle;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    color: #ffffff;
    margin-top: 10px;
}

.file-path-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.searchTerm {
    padding: 10px;
    border: 0;
    border-radius: 10px;
    background: url(/assets/icons/search.svg) no-repeat scroll 10px 10px;
    padding-left: 35px;
    box-sizing: border-box;
    width: 100%;
    background-image: linear-gradient(
        180deg,
        hsl(228deg 68% 89%) 0%,
        hsl(228deg 69% 90%) 11%,
        hsl(228deg 70% 91%) 22%,
        hsl(228deg 71% 92%) 33%,
        hsl(229deg 73% 93%) 44%,
        hsl(229deg 75% 94%) 56%,
        hsl(229deg 78% 95%) 67%,
        hsl(230deg 82% 96%) 78%,
        hsl(230deg 88% 97%) 89%,
        hsl(230deg 100% 98%) 100%
      );
}

.searchTerm::placeholder {
    color: #545454;
}

.popup-title {
    top: 0;
    background-color: white;
    position: sticky;
    display: flex;
    justify-content: space-between;
    z-index: 10;
    align-items: center;
    border-bottom: 1px solid var(--gray);
    margin-bottom: 10px;
    width: 100%;
    padding: 5px 0;
    border-radius: 12px 12px 0 0;
    box-sizing: border-box;
    > img {
        width: 20px;
        height: 20px;
        cursor: pointer;
        padding: 5px;
        border-radius: 8px;
    }
    > div {
        font-size: 15px;
        font-weight: bold;
        color: var(--main-color);
    }
}

.e-info {
    &.e-checkbox-wrapper {

        // styles for the checkbox frame
        .e-frame {
            height: 24px;
            line-height: 19px;
            width: 24px;
            border-radius: 50px;
        }

        // styles for the check mark icon
        .e-check {
            font-size: 18px;
            background-color: #2fb9d3 !important;
            outline: 2px solid #2fb9d3 !important;
            outline-offset: 3px;
        }

        // styles for the label text
        .e-label {
            font-size: 18px;
        }
    }
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: var(--main-color) !important;
}

.uploader-status {
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.border-radius-bottom-10 {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.notification-item-wrapper {
    // height: 40px;
    border-radius: 11px;

    &:not(.slim) {

        background-image: linear-gradient(#ffffff, rgba(56, 115, 244, 0.6));
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }

    &.is-read {

        background-image: linear-gradient(#ffffff, rgba(56, 115, 244, 0.2));
    }

    .main-text {
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.08px;
        text-align: left;
        color: #2e3d90;
    }

    .date {
        letter-spacing: 0.01em;
        text-align: left;
        color: #2b356c;
    }
}
.lesson-info-change-status.p-overlaypanel {
    .p-overlaypanel-content {
        padding: 0 0.5rem;
    }
}
.header-user-active.p-overlaypanel {
    position: fixed;
    margin-top: 10px;
    top: 70px!important;
    display: block!important;
    &::before, &::after {
        left: calc(var(--overlayArrowLeft, 0) + 6.25rem);
        border-bottom-color: #8093fb;
    }
    .p-overlaypanel-content {
        background: linear-gradient(#8497ff 0%, #122171 100%);
        border-radius: 8px;
        padding: 0;
    }
}
// :host ::ng-deep {

//     .p-overlaypanel.p-component {
//         position: fixed;
//     }
//     .header-overlay.p-overlaypanel {
//         margin-top: 10px;
//         top: 0;
//         left: 0;
//     }
// }

.note-badge .p-badge {
    background: #09c;
    border: 1px solid #fff;
    font-family: $font-family-light;
}

:host ::ng-deep {

    .e-radio:focus + label::before {
        border-color: var(--main-color) !important;
        box-shadow: none !important;
    }

    .e-radio:focus + label::before {
        border-color: #757575 !important;
        box-shadow: none !important;
    }

    .e-radio:hover + label::before {
        border-color: #757575 !important;
    }

    .e-radio:checked + label::before {
        background-color: #fff !important;
        border-color: var(--main-color) !important;
    }

    .e-radio:checked + label::after {
        background-color: var(--main-color) !important;
        color: var(--main-color) !important;
    }

    .e-radio:checked + label:active .e-ripple-element {
        background-color: rgba(227, 22, 91, 0.26) !important;
    }

    .e-radio:checked + .e-focus .e-ripple-container {
        background-color: rgba(227, 22, 91, 0.26) !important;
    }

    .e-radio:checked + .e-focus::before {
        outline: #fff 0 solid !important;
        outline-offset: 0 !important;
    }

    .e-radio:checked:focus + label::before {
        border-color: var(--main-color) !important;
    }

    .e-radio:checked:focus + label::after {
        background-color: var(--main-color) !important;
    }

    .e-radio:checked + label:hover::before {
        border-color: var(--main-color) !important;
    }

    .e-radio:checked + label:hover::after {
        background-color: var(--main-color) !important;
    }
}

.p-float-label > label {

    color: var(--blue-9);
    font-size: #{fluid(10px, 14px, 320px, 1600px)};
}

.dropdown-blue.p-dropdown {
    background-image: linear-gradient(#ffffff, #e3ebfd);
    height: 42px;
    border: 1px solid var(--my-gray);
    justify-content: start;
    align-items: center;
    display: flex;
    border-radius: 10px;

    &.with-icon {
        padding-left: 30px;
    }
    &.white-bg {
        background: #ffffff;
    }
    &.rounded {
        border-radius: 50px;
    }
    &.rounded-less {
        border-radius: 10px;
    }
    .p-dropdown-label {
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.01em;
        text-align: left;
        color: var(--blue-9);
        opacity: 1; /* Firefox */


        @include fluid-typography(12,14,300,992);
        @include fluid-typography(12, 14,992,1500);


        &.selected,.selected, &.p-placeholder {
            opacity: 0.5; /* Firefox */
        }

        // @include fluid-typography(14, 16,1200,1600);
        // font-size: #{fluid(10px, 12px, 320px, 992px)};
        // font-size: #{fluid(14px, 16px, 992px, 1200px)};
        // @include breakpoint(small-laptop) {
        //         font-size: #{fluid(10px, 12px, 320px, 1600px)};
        //     }
        // @include breakpoint(tablet) {
        //         font-size: #{fluid(12px, 14px, 320px, 1600px)};
        //     }

        //     @include breakpoint(laptop) {
        //         font-size: #{fluid(14px, 16px, 320px, 1600px)};
        //     }
        // @include fluid-typography(14,16,300,1600);
    }
    &:hover {
        border: 1px solid var(--blue-7)!important;
    }

    &.phone-code-input {
        border-right: 1px solid transparent;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .p-dropdown-trigger {
            width: 0.5rem;
            position: relative;
            left: -6px;
        }
        &.tiny {


            @include breakpoint(laptop) {
                width: 52px;
            }
        }
    }
}

.no-data {
    width: 100%;
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    .title {
        font-weight: bold;
        font-size: 1rem;
        padding: 30px;
    }
    img {
        width: 300px;
    }
}

.add {
    cursor: pointer;
    width: 50px;
}

.p-10 {
    padding: 10px;
}

.has-text-centered {
    clear: both;
    width: 100%;
    display: flex;
    justify-content: center;
}

.delete-line {
    width: 100%;
    height: 1px;
    background-color: var(--main-color);
    margin: 10px 0 5px 0;
    opacity: 0.4;
}

.m-t-0 {
    margin-top: 0 !important;
}

.section-bg-gradient {
    padding: 7.5px 54px 32px 20px;
    // opacity: 0.25;
    border-radius: 12px;
    background-image: linear-gradient(to bottom, #fff, rgba(56, 115, 244, 0.25));
}



.navbar .dropdown-menu1 {
    overflow: visible;
    left: -0.5625rem
}

.navbar .dropdown-menu1.navbar-dropdown-caret:after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 1.5rem;
    height: 1.5rem;
    background: var(--phoenix-black);
    top: -10px;
    left: 1.5rem;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background: var(--phoenix-white);
    background: inherit;
    border-top-left-radius: .25rem;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: var(--phoenix-gray-300);
    -webkit-box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)
}

.navbar .dropdown-menu1.dropdown-menu1-end.navbar-dropdown-caret {
    left: auto;
    right: -0.5625rem
}

.navbar .dropdown-menu1.dropdown-menu1-end.navbar-dropdown-caret:after {
    right: .9rem;
    left: auto
}

.navbar.navbar-slim .dropdown-menu1.navbar-dropdown-caret:after {
    width: 1rem;
    height: 1rem;
    top: -9px
}

.navbar .dropdown-item {
    font-weight: 500
}

.navbar .dropdown-item:hover,.navbar .dropdown-item:focus {
    background-color: var(--phoenix-gray-200);
    color: var(--phoenix-gray-1000)
}



.arrow {
    position: absolute;
    top: -13px;
    right: 20px;
    border-style: solid;
    border-width: 0 13px 13px 13px;
    border-color: transparent transparent black transparent;
}

.arrow:after {
    content: '';
    position: absolute;
    top: 3px;
    left: -10px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent white transparent;
}
.dropdown-menu {
    width: 180px;
    border: 2px solid black;
    background-color: white;
    border-radius: 6px;
    padding: 0;
    max-height: 320px;
    margin-top: 20px;
}

.wrapper {
    max-height: 200px;
    overflow: auto;
}

.h-inherit {
    height: inherit!important;
}

.contact-form-wrapper .no-padding {
    .dropdown-blue.p-dropdown.with-icon{
        padding: 0;
    }
}

.completed-gradient-lesson-bg  {
    // background: $completed-gradient;
    background-image: linear-gradient(
        90deg,
        hsl(219deg 56% 44%) 0%,
        hsl(217deg 56% 45%) 15%,
        hsl(216deg 55% 47%) 29%,
        hsl(214deg 55% 49%) 42%,
        hsl(213deg 54% 50%) 54%,
        hsl(212deg 56% 52%) 64%,
        hsl(211deg 59% 54%) 74%,
        hsl(210deg 62% 56%) 83%,
        hsl(209deg 64% 57%) 92%,
        hsl(208deg 67% 59%) 100%
      );
    &.inverted {
        background-image: linear-gradient(90deg,
                hsl(192deg 65% 62%) 0%,
                hsl(194deg 72% 59%) 10%,
                hsl(195deg 78% 55%) 21%,
                hsl(196deg 83% 52%) 32%,
                hsl(197deg 100% 47%) 43%,
                hsl(199deg 100% 47%) 54%,
                hsl(204deg 89% 52%) 66%,
                hsl(210deg 88% 57%) 77%,
                hsl(218deg 85% 61%) 89%,
                hsl(227deg 79% 65%) 100%);
    }
}

.trial-completed-gradient-lesson-bg {
    background: linear-gradient(#64cfdd 0%, #48d 60.34%, #657aef 100%);
    background-image: linear-gradient(
        85deg,
        hsl(213deg 69% 57%) 0%,
        hsl(209deg 72% 55%) 11%,
        hsl(206deg 74% 54%) 22%,
        hsl(203deg 75% 54%) 33%,
        hsl(201deg 75% 54%) 44%,
        hsl(199deg 74% 54%) 56%,
        hsl(198deg 73% 56%) 67%,
        hsl(196deg 71% 58%) 78%,
        hsl(195deg 68% 60%) 89%,
        hsl(193deg 65% 62%) 100%
      );
}

.requested-gradient-lesson-bg,.trial-requested-gradient-lesson-bg {
    background-image: linear-gradient(
        230deg,
        hsl(277deg 54% 55%) 0%,
        hsl(276deg 50% 54%) 11%,
        hsl(274deg 46% 53%) 22%,
        hsl(273deg 42% 52%) 33%,
        hsl(271deg 39% 51%) 44%,
        hsl(270deg 36% 50%) 56%,
        hsl(268deg 34% 49%) 67%,
        hsl(267deg 32% 48%) 78%,
        hsl(266deg 31% 46%) 89%,
        hsl(264deg 29% 45%) 100%
      );
}

.trial-gradient-lesson-bg {
    background: linear-gradient(#a795ff 0%, #6e25ff 100%);
    background: $trial-default-gradient;
    &:hover {
        background: $trial-hover-gradient;
    }
    &.inverted {

        background-image: linear-gradient(190deg,
                hsl(248deg 90% 73%) 0%,
                hsl(247deg 88% 72%) 8%,
                hsl(246deg 87% 71%) 16%,
                hsl(246deg 85% 70%) 24%,
                hsl(245deg 84% 70%) 33%,
                hsl(244deg 83% 69%) 42%,
                hsl(243deg 82% 68%) 52%,
                hsl(242deg 80% 67%) 64%,
                hsl(240deg 79% 67%) 80%,
                hsl(239deg 78% 65%) 100%);
    }
}

.arranged-gradient-lesson-bg {
    background: linear-gradient(to left, #6a61d4 0%, #5b43ba 100%) !important;
    background: $arranged-default-gradient;
    &:hover {
        background: $trial-hover-gradient;
    }
    &.inverted {

        background-image: linear-gradient(190deg,
                hsl(248deg 90% 73%) 0%,
                hsl(247deg 88% 72%) 8%,
                hsl(246deg 87% 71%) 16%,
                hsl(246deg 85% 70%) 24%,
                hsl(245deg 84% 70%) 33%,
                hsl(244deg 83% 69%) 42%,
                hsl(243deg 82% 68%) 52%,
                hsl(242deg 80% 67%) 64%,
                hsl(240deg 79% 67%) 80%,
                hsl(239deg 78% 65%) 100%);
    }
}

.trial-arranged-gradient-lesson-bg {
    background: $trial-arranged-default-gradient;
    background-image: linear-gradient(
        180deg,
        hsl(251deg 100% 78%) 0%,
        hsl(252deg 100% 76%) 11%,
        hsl(253deg 100% 75%) 22%,
        hsl(253deg 100% 73%) 33%,
        hsl(254deg 100% 71%) 44%,
        hsl(255deg 100% 69%) 56%,
        hsl(256deg 100% 67%) 67%,
        hsl(257deg 100% 65%) 78%,
        hsl(258deg 100% 62%) 89%,
        hsl(260deg 100% 60%) 100%
      );
}

.pending-gradient-lesson-bg {
    background-image: linear-gradient(
        270deg,
        hsl(245deg 57% 61%) 0%,
        hsl(245deg 56% 59%) 11%,
        hsl(246deg 54% 58%) 22%,
        hsl(247deg 53% 57%) 33%,
        hsl(248deg 52% 56%) 44%,
        hsl(249deg 51% 55%) 56%,
        hsl(250deg 49% 53%) 67%,
        hsl(250deg 48% 52%) 78%,
        hsl(251deg 47% 51%) 89%,
        hsl(252deg 47% 50%) 100%
      );
}

// background-image: linear-gradient(
//     90deg,
//     hsl(254deg 50% 47%) 0%,
//     hsl(253deg 47% 49%) 11%,
//     hsl(251deg 47% 51%) 22%,
//     hsl(250deg 49% 53%) 33%,
//     hsl(248deg 51% 55%) 44%,
//     hsl(247deg 53% 57%) 56%,
//     hsl(245deg 55% 59%) 67%,
//     hsl(244deg 57% 61%) 78%,
//     hsl(242deg 60% 63%) 89%,
//     hsl(241deg 63% 65%) 100%
//   );

.primary-gradient-lesson-bg {
    background-image: linear-gradient(
        90deg,
            hsl(231deg 53% 33%) 0%,
            hsl(229deg 51% 36%) 18%,
            hsl(227deg 50% 40%) 35%,
            hsl(225deg 49% 43%) 50%,
            hsl(223deg 48% 46%) 61%,
            hsl(221deg 47% 49%) 70%,
            hsl(219deg 50% 52%) 78%,
            hsl(218deg 56% 56%) 85%,
            hsl(217deg 63% 59%) 93%,
            hsl(215deg 71% 62%) 100%
        );
}

.expired-gradient-lesson-bg, .canceled-gradient-lesson-bg {
    background-image: linear-gradient(
        180deg,
        hsl(346deg 72% 63%) 0%,
        hsl(348deg 72% 63%) 11%,
        hsl(349deg 71% 64%) 22%,
        hsl(350deg 71% 64%) 33%,
        hsl(352deg 71% 64%) 44%,
        hsl(353deg 70% 65%) 56%,
        hsl(354deg 70% 65%) 67%,
        hsl(356deg 69% 66%) 78%,
        hsl(357deg 69% 66%) 89%,
        hsl(358deg 68% 67%) 100%
      );
    // background-image: linear-gradient(90deg,
    //         hsl(23deg 82% 63%) 0%,
    //         hsl(21deg 81% 64%) 11%,
    //         hsl(19deg 80% 64%) 22%,
    //         hsl(17deg 79% 65%) 33%,
    //         hsl(15deg 78% 65%) 44%,
    //         hsl(13deg 76% 65%) 56%,
    //         hsl(11deg 74% 65%) 67%,
    //         hsl(9deg 72% 66%) 78%,
    //         hsl(7deg 70% 66%) 89%,
    //         hsl(5deg 67% 66%) 100%);
}

.trial-canceled-gradient-lesson-bg {
    background-image: linear-gradient(
        0deg,
        hsl(356deg 78% 68%) 0%,
        hsl(357deg 78% 68%) 11%,
        hsl(359deg 78% 68%) 22%,
        hsl(360deg 79% 69%) 33%,
        hsl(1deg 79% 69%) 44%,
        hsl(2deg 79% 69%) 56%,
        hsl(3deg 79% 69%) 67%,
        hsl(4deg 80% 69%) 78%,
        hsl(5deg 80% 69%) 89%,
        hsl(6deg 80% 68%) 100%
      );
    // background-image: linear-gradient(90deg,
    //         hsl(23deg 82% 63%) 0%,
    //         hsl(21deg 81% 64%) 11%,
    //         hsl(19deg 80% 64%) 22%,
    //         hsl(17deg 79% 65%) 33%,
    //         hsl(15deg 78% 65%) 44%,
    //         hsl(13deg 76% 65%) 56%,
    //         hsl(11deg 74% 65%) 67%,
    //         hsl(9deg 72% 66%) 78%,
    //         hsl(7deg 70% 66%) 89%,
    //         hsl(5deg 67% 66%) 100%);
}

.frozen-gradient-lesson-bg {
    background-image: linear-gradient(90deg,
            hsl(221deg 74% 56%) 0%,
            hsl(219deg 77% 57%) 11%,
            hsl(218deg 78% 59%) 22%,
            hsl(216deg 80% 61%) 33%,
            hsl(215deg 81% 64%) 44%,
            hsl(214deg 81% 66%) 56%,
            hsl(213deg 81% 69%) 67%,
            hsl(212deg 81% 71%) 78%,
            hsl(212deg 81% 74%) 89%,
            hsl(211deg 80% 77%) 100%);
}

.no-show-gradient-lesson-bg {
    background-image: linear-gradient(90deg,
            hsl(221deg 74% 56%) 0%,
            hsl(219deg 77% 57%) 11%,
            hsl(218deg 78% 59%) 22%,
            hsl(216deg 80% 61%) 33%,
            hsl(215deg 81% 64%) 44%,
            hsl(214deg 81% 66%) 56%,
            hsl(213deg 81% 69%) 67%,
            hsl(212deg 81% 71%) 78%,
            hsl(212deg 81% 74%) 89%,
            hsl(211deg 80% 77%) 100%);
}

.dismissed-gradient-lesson-bg {
    background-image: linear-gradient(
        180deg,
        hsl(230deg 91% 87%) 0%,
        hsl(230deg 87% 84%) 11%,
        hsl(230deg 84% 82%) 22%,
        hsl(230deg 82% 79%) 33%,
        hsl(230deg 79% 77%) 44%,
        hsl(230deg 78% 74%) 56%,
        hsl(230deg 76% 71%) 67%,
        hsl(229deg 75% 68%) 78%,
        hsl(229deg 74% 66%) 89%,
        hsl(228deg 73% 63%) 100%
      );
}

.disabled-gradient-lesson-bg {
    background-color: #D1D7FF;
}

.join-lesson-btn {
    background-image: linear-gradient(270deg, hsl(245, 57%, 61%) 0%, hsl(245, 56%, 59%) 11%, hsl(246, 54%, 58%) 22%, hsl(247, 53%, 57%) 33%, hsl(248, 52%, 56%) 44%, hsl(249, 51%, 55%) 56%, hsl(250, 49%, 53%) 67%, hsl(250, 48%, 52%) 78%, hsl(251, 47%, 51%) 89%, hsl(252, 47%, 50%) 100%);
    color: white !important;

    &.disabled {
        opacity: 0.6;
    }
}

@media screen and (max-width: 1400px) {
    .note-badge .p-badge {
        position: absolute;
        top: 4px;
        padding: 1px 3px;
        border-radius: 50%;
        font-size: 9px;
        min-width: 0.825rem;
        height: unset;
        line-height: unset;
    }
}
@media screen and (min-width: 1401px){
    .note-badge .p-badge {
        position: absolute;
        top: 4px;
        padding: 1px 3px;
        border-radius: 50%;
        min-width: 16px;
        height: initial;
        line-height: inherit;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media screen and (max-width: 768px) {
    // * {
    //     scrollbar-width: 0;
    // }

    // /* Chrome, Edge, and Safari */
    // *::-webkit-scrollbar {
    //     width: 0;
    // }

    // *::-webkit-scrollbar-track {
    //     background: transparent;
    // }

    // *::-webkit-scrollbar-thumb {
    //     background: transparent;
    // }
}

.p-datepicker-calendar {
    thead{
        th{
            font-family: Proxima Nova Regular;
            font-size: $text-xs;
            color: #031977;
        }
    }
    tbody{
        td >span{
                font-family: Proxima Nova Thin;
        }
    }
}

.vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    @media screen and (max-width: 768px) {
        position: unset;
        transform: unset;
    }
}

.classroom-cards-space {
    margin: 4rem 0;
    @media screen and (max-width: 768px) {
        margin: 1rem 0;
    }
}
.request-trial-form-wrapper{
    .p-datepicker {
        @media screen and (min-width: 768px) {
          top: 43px!important;
          left: auto!important;
          right: 0!important;
      }
    }
}

.p-dropdown-item{
    margin: 0!important;
    padding: 0.4rem 1rem!important;
}

.p-dropdown-filter {
    height: 2rem;
}

.phone-code-input{
    .country-name.ng-star-inserted{
        display: none!important;
    }
    .p-dropdown-label.p-inputtext.ng-star-inserted{
        padding-left: 0.45rem !important;
    }
}
.benefits-wrapper {
    .p-carousel-prev, .p-carousel-next{
        margin: 0!important;
    }
}

.transparent-mask {
    background-color: rgba(0, 0, 0, 0)!important;
}

.p-overlaypanel .p-overlaypanel-close {
    background: #fff;
    color: #000B4A;
    top: 0.5rem;
    right: 0.5rem;
    width: 22px;
    height: 22px;
    z-index: 5;
}

.dialog-time-input {
    background: rgb(200, 216, 252);
    background: linear-gradient(0deg, rgba(200, 216, 252, 1) 0%, rgba(241, 245, 254, 1) 100%);
    border:none;
    color: #031977;
}

.student-number-circle {
	display: inline-block;
	width: 18px;
	height: 18px;
	border-radius: 50%;
    background: linear-gradient(#657aef 0%, #1a266c 100%);
	color: white;
	text-align: center;
	line-height: 18px;
	font-size: 12px;
	margin-left: 5px;
  }

.col-25-per,
.col-10-per,
.col-12-per,
.col-13-per,
.col-11-per,
.col-9-per,
.col-8-per,
.col-5-per,
.col-14-per,
.col-14-per,
.col-20-per,
.col-25-per,
.col-18-per-tablet,
.col-20-per-tablet,
.col-8-per-tablet,
.col-14-per-tablet,
.col-24-per-mobile {
    box-sizing: border-box;
    color: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
}
.col-20-per {
    flex-basis: 20%;
    width: 20%;
}
.col-25-per {
    flex-basis: 25%;
    width: 25%;
}
.col-25-per-left {
    justify-content: start;
}
.col-25-per-right,
.col-10-per-right,
.col-2-per-right {
    justify-content: end;
}
.col-10-per {
    flex-basis: 10%;
    width: 10%;
}
.col-11-per {
    flex-basis: 11%;
    width: 11%;
}
.col-15-per {
    flex-basis: 15%;
    width: 15%;
}

.col-12-per {
    flex-basis: 12%;
    width: 12%;
}
.col-13-per {
    flex-basis: 13%;
    width: 13%;
}
.col-14-per {
    flex-basis: 14%;
    width: 14%;
}
.col-9-per {
    flex-basis: 9%;
    width: 9%;
}
.col-8-per {
    flex-basis: 8%;
    width: 8%;
}
.col-5-per {
    flex-basis: 5%;
    width: 5%;
}
.col-2-per {
    flex-basis: 2%;
    width: 2%;
}
.class-rating {
    display: inline-block;
    background-image: linear-gradient(90deg,
            hsl(231deg 72% 41%) 0%,
            hsl(233deg 60% 46%) 11%,
            hsl(233deg 54% 50%) 22%,
            hsl(233deg 57% 55%) 33%,
            hsl(233deg 60% 59%) 44%,
            hsl(233deg 64% 62%) 56%,
            hsl(232deg 69% 66%) 67%,
            hsl(232deg 74% 70%) 78%,
            hsl(231deg 82% 74%) 89%,
            hsl(231deg 91% 78%) 100%);

   .p-chip {
    background-color: transparent!important;
   }
    &.writing {
        background-image: linear-gradient(90deg,
                hsl(231deg 72% 41%) 0%,
                hsl(233deg 60% 46%) 11%,
                hsl(233deg 54% 50%) 22%,
                hsl(233deg 57% 55%) 33%,
                hsl(233deg 60% 59%) 44%,
                hsl(233deg 64% 62%) 56%,
                hsl(232deg 69% 66%) 67%,
                hsl(232deg 74% 70%) 78%,
                hsl(231deg 82% 74%) 89%,
                hsl(231deg 91% 78%) 100%);
    }
    
    &.speaking {
        background-image: linear-gradient(90deg,
                hsl(237deg 58% 53%) 0%,
                hsl(220deg 77% 47%) 11%,
                hsl(212deg 100% 43%) 22%,
                hsl(208deg 100% 44%) 33%,
                hsl(205deg 100% 44%) 44%,
                hsl(202deg 100% 45%) 56%,
                hsl(199deg 100% 44%) 67%,
                hsl(195deg 99% 44%) 78%,
                hsl(197deg 71% 55%) 89%,
                hsl(196deg 64% 61%) 100%);
    }
    
    &.reading {
        background-image: linear-gradient(90deg,
                hsl(230deg 92% 42%) 0%,
                hsl(243deg 67% 47%) 11%,
                hsl(251deg 68% 46%) 22%,
                hsl(257deg 69% 45%) 33%,
                hsl(262deg 71% 44%) 44%,
                hsl(267deg 72% 43%) 56%,
                hsl(271deg 73% 42%) 67%,
                hsl(275deg 75% 41%) 78%,
                hsl(279deg 76% 40%) 89%,
                hsl(282deg 77% 39%) 100%);
    }
    
    &.grammar {
        background-image: linear-gradient(90deg,
                hsl(233deg 90% 39%) 0%,
                hsl(222deg 100% 41%) 11%,
                hsl(217deg 100% 43%) 22%,
                hsl(213deg 100% 45%) 33%,
                hsl(209deg 100% 46%) 44%,
                hsl(205deg 100% 47%) 56%,
                hsl(201deg 100% 47%) 67%,
                hsl(197deg 100% 47%) 78%,
                hsl(193deg 100% 46%) 89%,
                hsl(189deg 90% 49%) 100%);
    }
    
    &.listening {
        background-image: linear-gradient(90deg,
                hsl(189deg 53% 52%) 0%,
                hsl(190deg 100% 39%) 11%,
                hsl(194deg 100% 40%) 22%,
                hsl(198deg 100% 41%) 33%,
                hsl(202deg 100% 41%) 44%,
                hsl(206deg 100% 41%) 56%,
                hsl(209deg 100% 41%) 67%,
                hsl(213deg 100% 40%) 78%,
                hsl(229deg 62% 47%) 89%,
                hsl(255deg 57% 45%) 100%);
    }
    
    &.revision {
        background-image: linear-gradient(90deg,
                hsl(277deg 91% 37%) 0%,
                hsl(281deg 93% 36%) 11%,
                hsl(285deg 95% 35%) 22%,
                hsl(288deg 96% 35%) 33%,
                hsl(291deg 96% 34%) 44%,
                hsl(295deg 94% 34%) 56%,
                hsl(297deg 91% 34%) 67%,
                hsl(301deg 86% 35%) 78%,
                hsl(303deg 82% 37%) 89%,
                hsl(306deg 79% 38%) 100%);
    }
    
    &.vocabulary {
        background-image: linear-gradient(
            90deg,
            hsl(234deg 67% 41%) 0%,
            hsl(254deg 56% 44%) 11%,
            hsl(267deg 54% 44%) 22%,
            hsl(278deg 51% 44%) 33%,
            hsl(288deg 48% 45%) 44%,
            hsl(298deg 44% 46%) 56%,
            hsl(308deg 42% 50%) 67%,
            hsl(315deg 48% 54%) 78%,
            hsl(322deg 55% 58%) 89%,
            hsl(328deg 61% 63%) 100%
          );
    }
}

.p-datepicker table th {
    border-bottom: none;
}

.filter-white {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(298deg) brightness(108%) contrast(102%);
}

.filter-blue {
    filter: invert(26%) sepia(64%) saturate(8147%) hue-rotate(218deg) brightness(69%) contrast(159%);
  }

  .primary-blue {
    &.p-checkbox {
        width: 18px;
        height: 18px;
      }
      &.p-checkbox-label {
        font-size: 0.875rem;
        color: var(--primary-color);
        cursor: pointer;
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
      &.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
        top: 1px;
        left: -5px;
      }
      &.p-checkbox .p-checkbox-box {
        border-radius: 50px;
        border-color: #5BB7D0;
      }
    
      &.p-checkbox .p-checkbox-box.p-highlight {
        border-radius: 50px;
        background-color: #5BB7D0;
        border-color: #5BB7D0;
      }

      &.bigger {
        &.p-checkbox {
            min-width: 24px;
            min-height: 24px;
        }
    }
    
    &.outlined {

        &.p-checkbox:not(.p-checkbox-disabled).p-checkbox-focused {
            box-shadow: none;
          }
          &.p-checkbox.p-checkbox-checked:not(.p-checkbox-disabled):hover {
            box-shadow: none;
          }
          &.p-checkbox.p-checkbox-checked:not(.p-checkbox-disabled).p-checkbox-focused {
            box-shadow: none;
          }

    &.p-checkbox {
        width: 18px;
        height: 18px;
        transform: scale(0.9);
        }
        &.p-checkbox .p-checkbox-box .p-checkbox-icon {
        transform: scale(1);
        }
        &.p-checkbox .p-checkbox-box.p-highlight {
            border-radius: 50px;
            background-color: #5BB7D0;
            border-color: #5BB7D0;
            outline: 2px solid #2fb9d3;
            outline-offset: 3px;
        }
    }
    
      &.p-checkbox .p-checkbox-box .p-checkbox-icon {
        transform: scale(1.3);
      }

  }

// hide captcha badge
// .grecaptcha-badge { 
//     visibility: hidden !important;
// }

// .rc-anchor-invisible {
//     visibility: hidden !important;
// }